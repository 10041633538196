import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby"
import { format } from "date-fns"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/styles/_index.scss';
import { newsPageURL } from '../../site/urls';
import { ImageModule } from "../common/Image_Module"
import NoImg from "../../images/no-image.png";

const MoreNewsDetails = ({newsData}) => {

    const data = useStaticQuery(graphql`
        query GetAllNews {
            glstrapi {
                blogs(limit:4) {
                    id
                    slug
                    title
                    post_on
                    tile_image {
                        url
                    }
                    ggfx_results {
                        src_cftle
                        transforms
                    }
                }
            }
        }
    `)

    const allNews = newsData ? newsData : data.glstrapi.blogs

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    return (
        <div className="news-details-more-wrapper">
            <Container>
                <Row>
                    <Col>
                    <h2>Other news that may interest you</h2>
                    </Col>
                </Row>
            <Row>
                <Col>
                    <Slider className="news-slider" {...settings}>
                        {
                            allNews.map((data, i) => {

                                return (
                                    <div className="news-card-wrap">
                                        <div className="news-card-img-zoom">
                                            <Link to={newsPageURL+data?.slug}>
                                              {data.tile_image.url ? (
                                                <ImageModule
                                                  imagename={"blog.tile_image.similarimg"}
                                                  ImageSrc={data.tile_image}
                                                  ggfx_results={data.ggfx_results}
                                                  altText={data.title + "- White & Co Real Estate"}
                                                  strapi_id={data.id}
                                                />
                                              ) : (
                                                <img src={NoImg} alt={"news - img"} />
                                              )}
                                            </Link>
                                        </div>
                                        <div className="news-card-content">
                                            <p>{data?.title}</p>
                                            <ul className="list-inline">
                                                <li className="list-inline-item"><i className="icon icon-calendar"></i><span>{format(new Date(data?.post_on), "eo MMM yyyy")}</span></li>
                                                <li className="list-inline-item"><div className="list-divider">/</div></li>
                                                <li className="list-inline-item"><span>Dubai</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default MoreNewsDetails